
import Vue from "vue";
import DiscoverMentorcastItems from "@/components/Discover/DiscoverMentorcastItems.vue";
import SearchMentorcast from "@/components/Discover/SearchMentorcast.vue";
import { mapGetters } from "vuex";
import DiscoverMentorcastItemsLoading from "@/components/Discover/DiscoverMentorcastItemsLoading.vue";
import { InformationData, Mentorcast } from "@/types/interfaces";
import UserDetailsModal from "@/components/Modals/UserDetailsModal.vue";
import MentorcastImageModal from "@/components/Modals/MentorcastImageModal.vue";
import moment from "moment";

export default Vue.extend({
  name: "DiscoverMentorsPage",
  components: {
    DiscoverMentorcastItems,
    UserDetailsModal,
    MentorcastImageModal,
    SearchMentorcast,
    DiscoverMentorcastItemsLoading
  },
  data: function() {
    return {
      filter: {
        searchVal: "",
        currentTab: ""
      },
      data: [] as any,
      page: 1,
      last_page: 0,
      loading: false,
      load_more: false,
      userData: {} as any,
      mentorcast: {} as any,
      infoData: [
        {
          name: "start_date",
          label: "Date",
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: "Time",
          icon: "time",
          value: null
        },
        {
          name: "seat_price",
          label: "Price per seat",
          icon: "price",
          value: null
        },
        {
          name: "audience",
          label: "Audience",
          icon: "3users",
          value: null
        },
        {
          name: "max_seats",
          label: "No. of seats",
          icon: "seat-block",
          value: null
        }
      ] as InformationData[]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      mentorcasts: "search/getMentorcasts"
    })
  },
  mounted() {
    this.onSearch(1, true);
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    showMentor(id: any) {
      const index = this.data.findIndex((i: any) => i.id == id);
      if (index > -1) {
        this.userData = this.data[index].user;
        this.$bvModal.show("user-details");
      }
    },
    showMentorcast(id: any) {
      const index = this.data.findIndex((i: any) => i.uuid == id);
      if (index > -1) {
        this.mentorcast = this.data[index];
        for (const key of Object.keys(this.mentorcast)) {
          const index = this.infoData.findIndex(i => i.name === key);
          if (index > -1) {
            if (key === "start_time") {
              this.infoData[index].value = moment(
                this.mentorcast[key],
                "hh:mm:ss"
              ).format("hh:mm A");
              continue;
            }
            this.infoData[index].value = this.mentorcast[
              key as keyof Mentorcast
            ];
          }
        }
        this.$bvModal.show("mentorcast-img");
      }
    },
    onFilter(filter: any) {
      this.filter = filter;
      this.onSearch(1, true);
    },
    onSearch(page = 1, isDefault = false) {
      if (this.loading) {
        return;
      }
      if (isDefault) {
        this.data = [];
        this.last_page = 0;
        this.page = 1;
      }
      this.loading = true;
      this.$store
        .dispatch("search/SEARCH_MENTORCASTS", {
          q: this.filter.searchVal || "",
          type: this.filter.currentTab,
          page
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
          this.loading = false;
        });
    },
    onLoadMore() {
      this.page++;
      this.onSearch(this.page);
    }
  }
});
