
import Vue from "vue";

export default Vue.extend({
  name: "SearchMentorcast",
  props: {
    filterData: Object
  },
  data() {
    return {
      tabs: [
        {
          name: (this as any).$t("discover.discoverMentorcastTabUpcoming"),
          type: "Upcoming"
        },
        {
          name: (this as any).$t(
            "discover.discoverMentorcastTabMostParticipant"
          ),
          type: "MostParticipant"
        },
        {
          name: (this as any).$t("discover.discoverMentorcastTabMostCommented"),
          type: "MostCommented"
        }
      ],
      data: {
        searchVal: "",
        currentTab: "Upcoming"
      }
    };
  },
  mounted() {
    this.data.searchVal = this.$route.params.q;
    if (this.$route.params.q) {
      this.emitSearch();
    }
  },
  methods: {
    setTab(tab: string) {
      this.data.currentTab = tab;
      this.emitSearch();
    },
    emitSearch() {
      this.$emit("search", this.data, true);
    }
  }
});
